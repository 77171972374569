(function ($) {
    let $fn = $(".comp_gallery");

    if ($fn.length) {
        $fn.on("click","[data-gallery]",function () {
            let $this = $(this),
                $data = $this.data("gallery");

            $this.lightGallery({
                dynamic: true,
                thumbnails: true,
                counter: true,
                download: true,
                closable: true,
                zoom: false,
                loadYoutubeThumbnail: true,
                youtubeThumbSize: 'mqdefault',
                videoMaxWidth: "1280px",
                autoplayFirstVideo: false,
                dynamicEl: $data,
            });
            $this.on('onAfterOpen.lg',function(event){
                $doc.find(".lg-inner").find(".lg-item").each(function (index) {
                    if($data[index].src.indexOf("youtube") > -1) {
                        $doc.find(".lg-thumb-item").eq(index).addClass("mod--video");
                    }
                })
            });
        });
    }
})(jQuery);